// import React from 'react';
// import Layout from "../components/layout"
// import HeroImages from '../components/Reusables/HeroImages'
// import SEO from '../components/seo'
// import styled from "styled-components"
// import HoverEffectBlocks from '../components/About/HoverEffectBlock'
// import SummaryBlock from '../components/Home/SummaryBlock'
// import Highlights from "../components/Home/Highlights/Highlights"
// import goldman from "../images/UpReachPartnerLogos/PartnerEmployerLogos/goldman.png"
// import { graphql } from 'gatsby';
// // import AlumniBlog from '../components/Alumni/alumniblog';

// const StyledWrapper = styled.div`
//     .accordion-start{
//         padding-top:54px;
//     }

//     .slaughter-img {
//         width: 500px;
//       }

//     .description-box{
//         max-width:570px;
//     }
// `

// const heading = { title: "Our Programmes" }

// const Almuni = ({data}) => {
//     const {allStrapiHeroImages:{edges:{0:{node:image}}},allStrapiStudentsPage:{edges:{0:{node:pageData}}},allStrapiUniversityPage:{edges:{0:{node:pageData1}}}} = data;
//     return (
//         <Layout>
//             <StyledWrapper>
//                 {/* <SEO title={} /> */}
//                 <HeroImages heroImage={image} />
//                 <div className="container-fluid py-5 px-0 padding-left-8 padding-right-8">
//                         <div className="col-lg-6 px-0">
//                             <h1>ALUMNI</h1>
//                             <p>Exclusively sponsored by </p>
//                             <img className="slaughter-img img-fluid pb-3" src={goldman} alt="diversitylogo"></img>
//                             <p className="description-box">The upReach Alumni Programme is for former upReach Associates, who automatically qualify when they graduate. 
//                             Whether you have just started your first graduate job, or did so many years ago and are seeking to progress, the Alumni programme is designed with you in mind.<br />
//                             <br />The programme consists of three core elements:</p>
//                         </div>
//                 </div>
//                 <HoverEffectBlocks programs={pageData.HoverEffectBlock} heading={heading} />
//                 <SummaryBlock summary={pageData1.Summary} />
//                 <Highlights />
//                 {/* <AlumniBlog /> */}
//             </StyledWrapper>
//         </Layout>
//     );
// }

// export default Almuni;

// export const query = graphql`
// query alumni{
//     allStrapiHeroImages(filter: {Slug: {eq: "StudentsPage"}}) {
//         edges {
//           node {
//             Image {
//               publicURL
//             }
//           }
//         }
//       }
//       allStrapiStudentsPage {
//         edges {
//         node {
//             HoverEffectBlock {
//             title
//             description
//             Cta {
//                 title
//                 link
//             }
//             image {
//                 publicURL
//             }
//             }
//         }
//       }
//     } 
//     allStrapiUniversityPage{
//         edges{
//           node{
//             SEOtitle
//             Summary{
//               title
//               description
//               image{
//                 publicURL
//               }
//             }}}}
// }
// `

import React, {useEffect, useState} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import {navigate} from "gatsby"

const StyledWrapper = styled.div`
  .align-center {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 15%;
  }

  .timer, .redirect {
    color: #464fd0;
    font-weight: 700;
  }

`

const NotFoundPage = () => {
    const [timer, setTimer] = useState(5);

    useEffect(() => {
        window.setTimeout(() => {
            navigate("/")
        }, 5000);
    }, []);

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => setTimer(timer - 1), 1000);
        }
    }, timer);

    return (
        <Layout>
            <SEO title="404: Not found"/>
            <StyledWrapper>
                <div className="container my-5 pt-5">
                    <h1>We've recently upgraded our website</h1>
                    <p>This page has moved and we’re redirecting you to our homepage in <span
                        className="timer">{timer} seconds</span></p>
                    <p>If you're not redirected, <a className="redirect" href="/">please click here</a></p>
                </div>
            </StyledWrapper>
        </Layout>
    );
}

export default NotFoundPage
